<template>
  <v-container class="container-page">
    <v-card class="mt-0 transparent">
      <v-card-text class="px-0 py-0">
        <v-tooltip
          v-if="!activedTabMediator"
          left>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mt-5 mr-2"
              color="accent"
              :to="{ name: 'UserNew' }"
              fixed
              bottom
              right
              fab
              dark
              v-on="on">
              <v-icon>{{ $root.icons.action.add }}</v-icon>
            </v-btn>
          </template>
          <span>Adicionar usuário</span>
        </v-tooltip>
        <v-tooltip
          v-else
          left>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mt-5 mr-2"
              color="accent"
              :to="{ name: 'UserNewMediator' }"
              fixed
              bottom
              right
              fab
              dark
              v-on="on">
              <v-icon>{{ $root.icons.action.add }}</v-icon>
            </v-btn>
          </template>
          <span>Adicionar mediador</span>
        </v-tooltip>

        <template>
          <v-tabs
            slider-color="accent"
            class="tabs-transparent">
            <v-tab
              ripple
              :to="{name: 'Users', params: { group: 'supervisor' } }">
              Supervisores
            </v-tab>
            <v-tab
              ripple
              :to="{name: 'Users', params: { group: 'negotiator' } }">
              Negociadores
            </v-tab>
            <v-tab
              ripple
              :to="{name: 'Users', params: { group: 'administrator' } }">
              Administradores
            </v-tab>
            <v-tab
              ripple
              :to="{name: 'Users', params: { group: 'financial' } }">
              Financeiro
            </v-tab>
            <v-tab
              v-if="$can('mediators')"
              ripple
              :to="{name: 'Users', params: { group: 'mediator' } }">
              Mediadores
            </v-tab>
          </v-tabs>
          <v-card
            flat
            class="transparent content-tab">
            <loading
              v-if="loading > 0"
              class="absolute-center" />

            <template v-else>
              <v-card
                v-if="users && users.length > 0"
                class="transparent elevation-0">
                <v-card-title>
                  <v-spacer />
                  <v-flex
                    xs12
                    sm5>
                    <v-text-field
                      v-model="search"
                      label="Buscar"
                      :append-icon="$root.icons.layout.search"
                      single-line
                      hide-details />
                  </v-flex>
                </v-card-title>
                <v-data-table
                  class="transparent table-sticky table-clicked"
                  :headers="headers"
                  :items="users"
                  :search="search">
                  <template
                    v-slot:item="{ item }"
                    class="transparent">
                    <tr>
                      <td @click="selectUser(item.id)">
                        {{ item.name }}
                      </td>
                      <td @click="selectUser(item.id)">
                        {{ item.email }}
                      </td>
                      <td @click="selectUser(item.id)">
                        {{ formatDate(item.insertedAt.seconds) }}
                      </td>
                      <!-- <td>
                        <v-btn icon class="mx-0"
                          :aria-label="`Excluir usuário ${props.item.name}`"
                          :title="`Excluir empresa ${props.item.name}`"
                          @click="removeUser(props.item)">
                          <v-icon color="darkGray">{{$root.icons.action.delete}}</v-icon>
                        </v-btn>
                      </td> -->
                    </tr>
                  </template>
                  <template v-slot:pageText="props">
                    Exibindo de {{ props.pageStart }} a {{ props.pageStop }} no total de {{ props.itemsLength }}
                  </template>
                  <empty-state
                    slot="no-results"
                    class="mt-2"
                    size="75px"
                    :icon="$root.icons.state.no_result">
                    Sua busca por "{{ search }}" não possui resultados
                  </empty-state>
                </v-data-table>
              </v-card>

              <empty-state
                v-else
                class="absolute-center"
                style="margin-top: -50px;"
                size="75px"
                :icon="$root.icons.link.users">
                Não há usuários cadastrados com esse perfil <br>

                <v-btn
                  v-if="!activedTabMediator"
                  color="accent"
                  class="mt-2"
                  :to="{ name: 'UserNew' }"
                  depressed
                  small>
                  Cadastrar usuário
                </v-btn>

                <v-btn
                  v-else
                  color="accent"
                  class="mt-2"
                  :to="{ name: 'UserNewMediator' }"
                  depressed
                  small>
                  Cadastrar mediador
                </v-btn>
              </empty-state>
            </template>
          </v-card>
        </template>
        <transition
          name="slide"
          mode="out-in"
          appear>
          <router-view />
        </transition>
      </v-card-text>
    </v-card>

    <modal
      btn-primary-label="Sim, quero excluir"
      :show="showModalDelete"
      :toolbar="true"
      @close="closeConfirm"
      @submit="confirmRemove">
      <h2
        slot="title"
        class="title">
        Confirmação
      </h2>
      <div slot="content">
        <p class="title mt-6">
          Deseja realmente excluir o usuario "{{ userToDelete.name }}" ?
        </p>
      </div>
    </modal>
  </v-container>
</template>

<script>
  import Modal from '@/components/ui/Modal'
  import EmptyState from '@/components/ui/EmptyState'
  import Loading from '@/components/ui/Loading'
  import { mapGetters } from 'vuex'

  export default {
    components: {
      Modal,
      EmptyState,
      Loading
    },
    data () {
      return {
        search: '',
        headers: [
          { text: 'Nome do usuário', value: 'name', sortable: false },
          { text: 'Email', value: 'email', sortable: false },
          { text: 'Data de criação', value: 'insertedAt', sortable: false }
          // { text: '', value: '' }
        ],
        userToDelete: {},
        showModalDelete: false
      }
    },
    computed: {
      ...mapGetters({
        users: 'user/list',
        loading: 'user/listLoading',
        isRender: 'user/listRender'
      }),
      activedTabMediator () {
        return this.$route.params.group === 'mediator'
      }
    },
    watch: {
      '$route': 'getList'
    },
    beforeMount () {
      this.getList()
    },
    methods: {
      selectUser (id) {
        this.$router.push({ name: 'UserGeneral', params: { userId: id } })
      },
      closeConfirm () {
        this.userToDelete = {}
        this.showModalDelete = false
      },
      removeUser (user) {
        this.userToDelete = user
        this.showModalDelete = true
      },
      confirmRemove () {
        let message = 'Usuário removido com sucesso'
        this.closeConfirm()
        this.$root.$emit('snackbar', {show: true, color: 'success', text: message})
        this.$announcer.set(message)
      },
      getList () {
        this.$store.dispatch('user/getList', this.$route.params.group)
      },
      formatDate (seconds) {
        const options = { year: 'numeric', month: 'long', day: '2-digit'}
        return new Date(seconds * 1000).toLocaleString('pt-BR', options)
      }
    }
  }
</script>

<style lang="sass">
  .content-tab
    height: calc(100vh - 50px) !important
</style>
